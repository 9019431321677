<template>
  <div class="form-group">
    <label>{{ $t('subtype') }}</label>
    <el-select v-model="model" v-on="$listeners" v-bind="$attrs" :placeholder="placeholder">
      <el-option value="">
        {{ placeholder }}
      </el-option>
      <el-option
        v-for="option in options"
        :key="option"
        :value="option"
        :title="$t(`messenger_subtype_info_${option}`)"
      >
        <i :class="`fa fa-info-circle text-${getInfoClass(option)} mt-1 mr-2`"></i> {{ option }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    currentType: {
      type: String,
      default: '',
      description: 'Current message type to get subtype options'
    },
    value: {
      type: String,
      default: '',
    }
  },
  computed: {
    placeholder() {
      return this.currentType ? this.$t('helper_allocationmethodtxt') : this.$t('messenger_subtype_placeholder');
    },
    options() {
      return this.currentType ? this.subtypeOptions[this.currentType] : []
    },
  },
  data() {
    return {
      subtypeOptions: {
        'email': ['system'],
        'push': ['user', 'hoko_admin_en', 'hoko_admin_pt', 'hoko_user_en', 'hoko_user_pt'],
      },
      model: this.value,
    }
  },
  methods: {
    handleInput(value) {
      if(typeof value === 'string') {
        this.model = value;
      }
      this.$emit('input', this.model)
    },
    getInfoClass(option) {
      return this.currentType === 'push' && option !== 'user' ? 'warning' : 'info'
    },
  },
  watch: {
    currentType() {
      this.handleInput('');
    },
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
label {
  font-size: $font-size-small;
  margin-bottom: 5px;
  color: $dark-gray;
}
</style>
