import { render, staticRenderFns } from "./MessengerTypeSelect.vue?vue&type=template&id=c709100a&scoped=true&"
import script from "./MessengerTypeSelect.vue?vue&type=script&lang=js&"
export * from "./MessengerTypeSelect.vue?vue&type=script&lang=js&"
import style0 from "./MessengerTypeSelect.vue?vue&type=style&index=0&id=c709100a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c709100a",
  null
  
)

export default component.exports