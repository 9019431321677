<template>
<modal id="messengerForm"
       :required="true"
       :show.sync="showFormModal"
       headerClasses="justify-content-center"
       modalClasses="modal-lg">
    <h4 slot="header" class="title title-up">{{ getFormTitle }}</h4>

    <div class="row">
      <fieldset>
        <div class="row">
          <fg-input
            class="col-7 req"
            v-model="template.template"
            :label="$t('template')"
            @blur="onValidate($event.target.value, $event.target)"
          />
          <messenger-language-select
            id="lang"
            class="col-5 req"
            v-model="template.lang"
            @change="onValidate($event, $el.querySelector('#lang input'))"
          />
          <messenger-type-select
            id="type"
            class="col-7 req"
            v-model="template.type"
            @change="onValidate($event, $el.querySelector('#type input'))"
            v-if="!promoContext"
          />
          <messenger-subtype-select
            id="subtype"
            class="col-5 req"
            v-model.lazy="template.subtype"
            @change="onValidate($event, $el.querySelector('#sybtype input'))"
            v-if="!promoContext"
            :current-type="template.type"
          />
          <fg-input
            class="col-12 req"
            v-model="template.subject"
            :label="$t('messagesubject')"
            @blur="onValidate($event.target.value, $event.target)"
          />
          <div id="editor" class="form-group col-12">
            <label>{{ $t('body') }}</label>
            <ckeditor v-if="!isPushing" :config="editorConfig" v-model="template.body"></ckeditor>
            <textarea v-if="isPushing" v-model="template.body" rows="1" maxlength="400" class="form-control"></textarea>
          </div>
        </div>
      </fieldset>

    </div>

    <template slot="footer">
      <div class="left-side">
        <p-button link @click="submit">{{ getConfirmButtonLabel }}</p-button>
      </div>
      <div class="divider"></div>
      <div class="right-side">
        <p-button type="danger" link @click="clean">{{ $t('cancel') }}</p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import {mapActions, mapState} from 'vuex';
import Modal from 'src/components/UIComponents/Modal';
import MessengerLanguageSelect from 'src/components/Dashboard/Views/Dashboard/Messenger/MessengerLanguageSelect';
import MessengerTypeSelect from "src/components/Dashboard/Views/Dashboard/Messenger/MessengerTypeSelect";
import MessengerSubtypeSelect from "src/components/Dashboard/Views/Dashboard/Messenger/MessengerSubtypeSelect";

Vue.use(CKEditor);

export default {
  components: {MessengerTypeSelect, MessengerSubtypeSelect, Modal, MessengerLanguageSelect},
  computed: {
    ...mapState('messenger', ['showFormModal', 'promoContext']),
    getFormTitle() {
      return this.isEditing() ? this.$t('templateedit') : this.$t('templatecreate');
    },
    getConfirmButtonLabel() {
      return this.isEditing() ? this.$t('savechanges') : this.$t('create');
    },
    isPushing() {
      return this.template.type === 'push';
    }
  },
  data() {
    return {
      cleanTemplate: {
        id: '',
        template: '',
        subject: '',
        body: '<p>Dear {first_name} {last_name},</p>',
        lang: '',
        active: 1,
        type: this.promoContext ? 'email' : '',
        subtype: this.promoContext ? 'promo' : '',
      },
      template: {},
      editorConfig: {
        height: '300px'
      },
      invalidClass: 'is-invalid',
    }
  },
  methods: {
    ...mapActions('messenger', ['clean']),
    isEditing() {
      return this.$store.state.messenger.currentRow && this.$store.state.messenger.currentRow.template;
    },
    onValidate(value, el) {
      if(value) {
        el.classList.remove(this.invalidClass);
      } else {
        el.classList.add(this.invalidClass);
      }
    },
    async validate() {
      this.$el
          .querySelectorAll('.req input')
          .forEach(el => this.onValidate(el.value, el));
      return this.$el
                 .querySelector('.is-invalid') == null;
    },
    async submit() {
      let response;

      // forces promo flow to persist promo type or else test if required fields are filled
      if(this.promoContext) {
        this.template.type = 'email';
        this.template.subtype = 'promo';
      }

      const isValid = await this.validate();

      if(!isValid) {
        this.$toast.warning(this.$t('needed'));
        return;
      }

      if(this.isEditing()) {
        response = await this.$editPromoTempates(this.template);
      } else {
        response = await this.$createPromoTempates(this.template);
      }

      if(!response.success) {
        this.$toast.warning(this.$t(response.msg));
        return;
      }

      this.$toast.success(this.$t('templatesuccess'))

      this.clean();
    },
  },
  watch: {
    showFormModal(val) {
      this.$el.querySelectorAll('input.req')
              .forEach(el => el.classList.remove(this.invalidClass));
      if (val) {
        this.template = !!this.isEditing() && Object.keys(this.$store.state.messenger.currentRow).length ?
          {...this.$store.state.messenger.currentRow} :
          {...this.cleanTemplate};
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
label {
  font-size: $font-size-small;
  margin-bottom: 5px;
  color: $dark-gray;
}
</style>
<style lang="scss">
#editor {
  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    height: 300px;
  }
}
</style>
