<template>
  <div class="form-group">
    <label>{{ $t('user_lang') }}</label>
    <el-select v-on="$listeners" v-bind="$attrs" :placeholder="$t('helper_allocationmethodtxt')">
      <el-option value="">
        {{ $t('helper_allocationmethodtxt') }}
      </el-option>
      <el-option value="en">
        <img :src="getFlag('en')" alt="flag" class="dropdown-toggle"> {{ $t('english') }}
      </el-option>
      <el-option value="pt">
        <img :src="getFlag('pt')" alt="flag" class="dropdown-toggle"> {{ $t('portugues') }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  methods: {
    getFlag(lang) {
      const flag = lang.toUpperCase() === 'EN' ? 'GB' : 'PT';
      return `/static/img/flags/${flag}.png`;
    },
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
label {
  font-size: $font-size-small;
  margin-bottom: 5px;
  color: $dark-gray;
}
</style>
